


















import { SearchBuilder } from "@/builder";
import { debounce } from "@/helpers/debounce";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { userRoleService } from "@/services/user-role.service";
import { LabelInValue } from "@/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RoleSelect extends Vue {
  @Prop({ required: false, default: undefined }) value!: LabelInValue[];
  @Prop({ required: false }) disabled!: boolean;

  roleOption = {
    data: [] as Option[],
    search: "",
    fetching: true,
  };

  created() {
    this.getRoleList();
  }

  async getRoleList(params?: RequestQueryParamsModel) {
    try {
      this.roleOption.fetching = true;
      const res = await userRoleService.getList(params);
      this.roleOption.data = res.data.map(item => ({
        label: item.name,
        key: item.id,
        value: item.id,
        meta: item,
      }));
      this.roleOption.fetching = false;
    } catch (error) {
      this.roleOption.fetching = false;
    }
  }

  searchRole(value?: string): void {
    debounce(() => {
      const params = new RequestQueryParams();

      if (value) {
        params.search = new SearchBuilder()
          .push(["name", value], { like: "both" })
          .or()
          .push(["description", value], { like: "both" })
          .build();
      }

      this.getRoleList(params);
    });
  }

  handleChange(e?: LabelInValue[]): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }
}
