var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-select", {
    attrs: {
      showSearch: true,
      filterOption: false,
      allowClear: true,
      loading: _vm.roleOption.fetching,
      placeholder: _vm.$t("common.select-text", { text: _vm.$t("lbl_role") }),
      disabled: _vm.disabled,
      value: _vm.value,
      options: _vm.roleOption.data,
      "label-in-value": "",
      dropdownMatchSelectWidth: false,
      mode: "multiple"
    },
    on: { search: _vm.searchRole, change: _vm.handleChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }