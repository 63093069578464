import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { ListRoleDto } from "@interface/user-role";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class UserRoleService extends HttpClient {
  getList(params?: RequestQueryParamsModel): Promise<Pagination<ListRoleDto>> {
    return this.get<Pagination<ListRoleDto>>(Api.Role, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const userRoleService = new UserRoleService();
